





























































































import axios from "axios";
import marked from "marked";
import Vue from "vue";
let rendererMD = new marked.Renderer();
marked.setOptions({
  renderer: rendererMD,
  gfm: true,
  breaks: false,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
});
rendererMD.heading = function (text, level) {
  return `<h${level} style="margin-bottom:20px;margin-top:20px">${text}</h${level}>`;
};
rendererMD.link = function (href, title, text) {
  return `<a style="color:#4183c4;text-decoration: none;" href=${href}>${text}</a>`;
};
export default Vue.extend({
  data: () => ({
    serveraddr: Vue.prototype.serverAddr + "/",
    imagelist: [] as any,
    rules: [(value: any) => !!value || "Required"],
    name: "",
    engName: "",
    nickName: "",
    date: "",
    content: "",
    misc: "",
    processedContent: "",
    file: {} as any,
    type: "",
    group: "",
    grade: 0,
    groups: [
      "默认方向",
      "语音前端",
      "语音识别",
      "语音合成",
      "声纹识别",
      "多模态交互",
    ],
    types: ["本科", "硕士研究生", "博士研究生", "毕业学生", "访问学者"],
    picStart: '<img src="localhost/',
    picEnd: '" width=100%/>',
  }),
  computed: {
    compiledMarkdown: function () {
      return marked(this.processedContent);
    },
  },
  mounted: function () {
    console.log(this.$route.params.id);
    const id = this.$route.params.id;
    if (id != "!") {
      console.log("getting " + id);
      let article = axios
        .post(Vue.prototype.serverAddr + "/api/get-item-by-id", {
          collection: "staff",
          _id: id,
        })
        .then((response) => {
          let data: [] = response.data as any;
          console.log(data)
          if (data.length > 0) {
            const item = (data as any)[0];
            this.name = item.name;
            this.engName = item.engName;
            this.nickName = item.nickName;
            this.content = item.content != undefined ? item.content : "";
            this.misc = item.misc;
            this.processedContent = this.content.replace(
              /localhost/g,
              Vue.prototype.serverAddr
            );
            this.imagelist = item.imagelist != undefined ? item.imagelist : [];
            this.group = this.groups[item.group];
            this.type = item.type;
            this.grade = item.grade;
          }
        });
    }
  },
  watch: {},
  methods: {
    deletePic: function (image: any) {
      this.imagelist.splice(this.imagelist.indexOf(image), 1);
    },
    update: function (e: any) {
      this.content = e;
      this.processedContent = this.content.replace(
        /localhost/g,
        Vue.prototype.serverAddr
      );
    },
    submit: function () {
      let method = "";
      if (this.$route.params.id == "!") {
        method = "post";
      } else {
        method = "update";
      }
      axios
        .post(Vue.prototype.serverAddr + "/api/" + method + "-item", {
          _id: this.$route.params.id,
          collection: "staff",
          name: this.name,
          engName: this.engName,
          nickName: this.nickName,
          misc: this.misc,
          content: this.content,
          imagelist: this.imagelist,
          type: this.type,
          grade: this.grade,
          group: this.groups.indexOf(this.group),
        })
        .then((response) => {
          this.$router.back();
        });
    },
    cancel: function () {
      this.$router.back();
    },
    getFile(file: any) {
      this.file = file;
    },
    uploadFile() {
      const URL = Vue.prototype.serverAddr + "/api/upload";

      let data = new FormData();
      data.append("name", "my-picture");
      data.append("file", this.file);

      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };

      axios.post(URL, data, config as any).then((response) => {
        console.log("image upload response > ", response);
        if (response.status == 200) {
          this.imagelist.push(this.file.name);
        }
      });
    },
  },
});
